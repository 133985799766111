.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
}
.nav-menu{
    display:flex;
}

.nav-menu li{
    padding: 0 1rem;
}

.nav-menu li a{
    font-size: 1.0rem ;
    font-weight: 600;
    color: rgb(15, 54, 31);
}

.nav-menu li a:hover{
  color: rgb(220, 136, 53);
  text-shadow: 0px 0px 6px rgba(255, 255, 255, 1);
  -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
  -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
  transition: all 0.4s ease 0s;
	padding-left: 1px;
}
@media screen and (max-width: 500px) {
    .header li a {
      float: none;
      display: block;
      text-align: left;
    }
    .header-right {
      float: none;
    }
  }

.img-wrapper {
    display:inline-block; 
    height:50px; 
    overflow:hidden; 
    width:50;
    padding-left: 1rem;
}
.img-wrapper img {height: 50px;}

.text{
  color: rgb(129, 5, 5);
  background: rgb(188, 141, 22);
  font-family: Poppins;
  font-size: 20px;
  -webkit-background-clip:text;
  -webkit-text-fill-color:rgb(29, 32, 32)
}

