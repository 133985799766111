* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins' sans-serif;
}

.grids {
    display: grid;
    grid-template-columns: repeat(5, 200px);
    justify-content: center;
    align-content: center;
    grid-gap: 10px;
    height: 100vh;
  }
  
  .grids img {
    width: 200px;
    height: 200px;
    cursor: pointer;
  }
  
  #lightbox {
    position: fixed;
    z-index: 1000;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    display: none;
  }
  
  #lightbox.active {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  #lightbox img {
    max-width: 90%;
    max-height: 80%;
    padding: 4px;
    background-color: black;
    border: 2px solid white;
  }