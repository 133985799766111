.holder{
   
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    height: auto;
   
}

.mainRegister{
    width: auto;
    height: auto;
    
    font-family: poppins;
    padding: 50px 0;
    display: grid;
    place-items: center;
}

.contains{
  background: rgba(77, 143, 132, 0.747);


}






* {box-sizing: border-box}

/* Add padding to containers */
.container {
  padding: 16px;
}

/* Full-width input fields */
input[type=text], input[type=password] {
  width: 100%;
  padding: 15px;
  margin: 5px 0 22px 0;
  display: inline-block;
  border: none;
  background: #f1f1f1;
}

input[type=text]:focus, input[type=password]:focus {
  background-color: #ddd;
  outline: none;
}

/* Overwrite default styles of hr */
hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}

/* Set a style for the submit/register button */
.registerbtn {
  background-color: rgb(5, 53, 54);
  color: white;
  padding: 16px 20px;
  margin: 8px 0;
  border: none;
  cursor: pointer;
  width: 100%;
  opacity: 0.9;
}

.registerbtn:hover {
  opacity:1;
}

/* Add a blue text color to links */
a {
  color: dodgerblue;
}

.text{

  background: linear-gradient(90deg, #2c3636, #59799c);
  font-family: Poppins;
  font-size: 20px;
  -webkit-background-clip:text;
  -webkit-text-fill-color: transparent;
}
.maintext{

  background: linear-gradient(90deg, #2c3636, #59799c);
  text-align: center;
  font-family: Poppins;
  font-size: 30px;
  -webkit-background-clip:text;
  -webkit-text-fill-color: transparent;
}

/* Set a grey background color and center the text of the "sign in" section */
.signin {
  background-color: #f1f1f1;
  text-align: center;
}
@media screen and (max-width: 980px) {
    .columns .column {
        margin-bottom: 5px;
        flex-basis: 40%;
    }
    
}